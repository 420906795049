'use client';

import { debounce } from '../utils';
import IconPlay from './img/play-icon';
import {
  DialogStyles,
  DotcomButtonStyles,
  DotcomButtonStylesSmall,
  VideoStyles,
} from './video.style';
import {
  VideoProps,
  VideoWindow,
  WidgetApiMethods,
  WidgetInstance,
  isNumber,
  isVideoMetadata,
} from './video.types';
import { Tracking, useAnalyticsTracking } from '@thrivent-web/analytics';
import { logger } from '@thrivent-web/logging-utils';
import { Dialog, PageLoader } from '@thrivent-web/ui/atoms';
import { CrossWhite } from '@thrivent-web/ui/icons';
import Script from 'next/script';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

//Need to do this because in storybook I don't have NextJS env variables
const qumuVideoURL =
  process.env['NEXT_PUBLIC_QUMU_VIDEO_URL'] ??
  'https://video.thrivent.com/widgets/application.js';

const {
  ImageContainer,
  Image,
  Button,
  PlayButton,
  Card,
  CardTitle,
  CardCaption,
  VideoContainer,
  LoaderWrapper,
} = VideoStyles;

const {
  ButtonOutline,
  DotcomPlayButton,
  DotcomText,
  DotcomTitle,
  DotcomCaption,
} = DotcomButtonStyles;

/**
 * @deprecated - use ModalVideoPlayer instead. import { ModalVideoPlayer } from '@exploration/shared-components';
 */
const Video = ({
  image,
  className,
  videoId,
  captionText,
  titleText,
  dotcomButton,
  brandRefresh = false,
}: VideoProps) => {
  const videoWindow =
    typeof window === 'undefined' ? null : (window as VideoWindow);
  const uniqueId = `qumuPlayerWrapper-${videoId}`;

  const { midwestColors: colors } = useTheme();
  const [open, setOpen] = useState(false);
  const [videoTitle, setVideoTitle] = useState<string>('');
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const titleRef = useRef<string>();
  const durationRef = useRef<number>();
  const [hasBeenGloballyInjected, setHasBeenGloballyInjected] = useState(true);
  const [widgetInstance, setWidgetInstance] = useState<
    WidgetInstance | undefined
  >(undefined);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);

  const { trackEvent } = useAnalyticsTracking();
  titleRef.current = videoTitle;
  durationRef.current = videoDuration;

  useEffect(() => {
    setTimeout(() => {
      if (videoWindow?.KV === undefined) {
        //If the app did not inject the app globally in the header we need to inject it here
        setHasBeenGloballyInjected(false);
      }
    }, 500);
  }, [videoWindow]);

  const showVideoModal = () => {
    //If for some reason the variable is still not there maybe because the CDN is down
    if (!videoWindow?.KV?.widget) {
      return logger.warn('There was an error trying to initialize Qumu Video');
    }

    // Remove the pointer-events style property to avoid focusing on the close button because it's showing an ugly border
    document.querySelector('body')?.style.removeProperty('pointer-events');

    setOpen(true);
    setIsSpinnerActive(true);
    const video = videoWindow.KV.widget({
      selector: '#' + uniqueId,
      type: 'playback',
      guid: videoId,
      autoLoad: true,
      autoPlay: true,
      loop: false,
      closeOverlayOnFinish: false,
      showInfoPanel: false,
      hideEmbedButton: true,
      hideDownloadButton: true,
      hideShareButton: true,
      player: {
        maxWidth: 1024,
      },
    });
    setWidgetInstance(video);

    const qumuApi = video?.api;
    if (qumuApi) {
      initializeVideoAnalytics(qumuApi);
    } else {
      logger.warn('There was an error initializing Video Analytics events');
    }
  };

  const getVideoDuration = (videoApi: WidgetApiMethods): Promise<number> => {
    return new Promise((resolve) => {
      videoApi.get('currentTime', (timeInMS) => {
        if (isNumber(timeInMS)) {
          return resolve(timeInMS / 1000);
        }
        resolve(0);
      });
    });
  };

  const sendVideoEndedEvent = async (videoApi: WidgetApiMethods) => {
    const duration = await getVideoDuration(videoApi);

    trackEvent({
      eventType: Tracking.videoEnded,
      eventInfo: {
        videoTitle: titleRef.current ?? '',
        videoGUID: videoId,
        value: duration?.toString(),
      },
    });
  };
  const initializeVideoAnalytics = (videoApi: WidgetApiMethods) => {
    // Bind video events to Adobe
    videoApi.bind('load', (videoMetadata) => {
      if (!isVideoMetadata(videoMetadata)) {
        return;
      }
      setIsVideoEnded(false);
      setIsSpinnerActive(false);

      const title = new DOMParser().parseFromString(
        videoMetadata.title,
        'text/html',
      )?.documentElement?.textContent;

      if (title) {
        setVideoTitle(title);
        setVideoDuration(videoMetadata.duration);
      }
    });

    videoApi.bind('play', async () => {
      const duration = await getVideoDuration(videoApi);

      setIsVideoEnded(false);
      setIsSpinnerActive(false);

      trackEvent({
        eventType: Tracking.videoPlay,
        eventInfo: {
          videoTitle: titleRef.current ?? '',
          videoGUID: videoId,
          value: duration?.toString(),
        },
      });
    });

    videoApi.bind('ended', () => {
      setIsVideoEnded(true);
      sendVideoEndedEvent(videoApi);
    });

    videoApi.bind(
      'volumechange',
      debounce((value) => {
        trackEvent({
          eventType: Tracking.videoVolumeChanged,
          eventInfo: {
            videoTitle: titleRef.current ?? '',
            videoGUID: videoId,
            value: (value as number).toString(),
          },
        });
      }, 800),
    );

    videoApi.bind('pause', async () => {
      const currentDuration = await getVideoDuration(videoApi);
      if (
        durationRef?.current &&
        currentDuration > durationRef?.current / 1000
      ) {
        return;
      }

      trackEvent({
        eventType: Tracking.videoPause,
        eventInfo: {
          videoTitle: titleRef.current ?? '',
          videoGUID: videoId,
          value: currentDuration?.toString(),
        },
      });
    });
  };

  const closeDialog = async () => {
    const videoWindow = window as VideoWindow;
    const widgetArr = videoWindow.KV?.widget?.widgets;
    const qumuApi = widgetInstance?.api;

    if (qumuApi && !isVideoEnded) {
      await sendVideoEndedEvent(qumuApi);
    }
    setOpen(false);

    // This is to prevent a memory leaking, so after close I'm removing the widgetInstance from the videoWidgets array
    // TODO: check if this is effectively getting removed by the JS garbage collector
    if (widgetArr?.length && widgetInstance) {
      const index = widgetArr.indexOf(widgetInstance);
      if (index > -1) {
        widgetArr.splice(index, 1);
      }
    }

    // Start destroying the API.
    // No more events will be sent, and no more commands can be issued.
    qumuApi?.command('destroy', [], () => {
      // Once the callback is executed the destroy is complete.
      // and you can remove the Player iframe from the page.
      // If you remove the iframe before the callback as fired view
      // won't get sent correctly.
    });
  };

  const VideoButton = ({ dotcomButton }: { dotcomButton?: string }) => {
    const playButtonBGColor = brandRefresh
      ? colors.identityBrandPrimaryHeavy
      : colors.identityBrandPrimaryStrong;

    if (dotcomButton === 'lg') {
      return (
        <ButtonOutline id={'video-play-button'}>
          <DotcomPlayButton brandRefresh={brandRefresh}>
            <IconPlay color={playButtonBGColor} />
          </DotcomPlayButton>
          <DotcomText>
            <DotcomTitle as={'p'}>{titleText}</DotcomTitle>
            <DotcomCaption as={'p'}>{captionText}</DotcomCaption>
          </DotcomText>
        </ButtonOutline>
      );
    }
    if (dotcomButton === 'sm') {
      return (
        <DotcomButtonStylesSmall.SmallButtonContainer>
          <ButtonOutline id={'video-play-button'}>
            <DotcomPlayButton brandRefresh={brandRefresh}>
              <IconPlay color={playButtonBGColor} />
            </DotcomPlayButton>
            <DotcomText>
              <DotcomTitle as={'p'}>{titleText}</DotcomTitle>
            </DotcomText>
          </ButtonOutline>
        </DotcomButtonStylesSmall.SmallButtonContainer>
      );
    }
    return (
      <Button>
        <Card>
          <PlayButton id={'video-play-button'}>
            <IconPlay />
          </PlayButton>
          <CardTitle as={'p'}>{titleText}</CardTitle>
          <CardCaption as={'p'}>{captionText}</CardCaption>
        </Card>
      </Button>
    );
  };

  return (
    <VideoContainer data-testid="video-section-container" className={className}>
      {/* //If the app did not inject the app globally in the header we need to inject it here */}
      {!hasBeenGloballyInjected && <Script src={qumuVideoURL} />}
      <Dialog open={open}>
        <DialogStyles.DialogContent
          onInteractOutside={closeDialog}
          zIndex={600}
        >
          <DialogStyles.DialogTitle>
            {`Video Dialog title: ${titleRef.current ?? 'untitled'}`}
          </DialogStyles.DialogTitle>
          <div
            data-testid="video-dialog"
            aria-label={`Video Dialog title: ${titleRef.current ?? 'untitled'}`}
          >
            <DialogStyles.CloseButton
              onClick={closeDialog}
              icon={<CrossWhite />}
              aria-label="Close Dialog"
            />
            <div className="video-wrapper">
              <LoaderWrapper>
                {isSpinnerActive && <PageLoader active />}
              </LoaderWrapper>
              <div id={uniqueId}></div>
            </div>
          </div>
        </DialogStyles.DialogContent>
      </Dialog>
      <ImageContainer
        onClick={showVideoModal}
        data-analytics-name={`video: ${titleText ?? 'untitled'}`}
      >
        {image && <Image {...image} />}
        <VideoButton dotcomButton={dotcomButton} />
      </ImageContainer>
    </VideoContainer>
  );
};

export default Video;
