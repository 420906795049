'use client';

import {
  CTAWithImageSectionContainerProps as CTAProps,
  CTAWithImageSectionSectionProps as SectionProps,
} from './cta-with-image.types';
import {
  ChevronRight as ChevronRightIcon,
  GridContainer,
  GridItem,
  SectionRoot,
} from '@exploration/shared-components';
import { desktop } from '@thrivent/midwest-shared';
import { MDSAnchorButton, MDSText } from '@thrivent/midwest-web';
import NextImage from 'next/image';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)<SectionProps>`
  background-color: ${(p) => p.backgroundColor};
`;

export const Container = styled(GridContainer)<CTAProps>`
  padding-top: ${(p) => (p.reverseMobile ? 0 : '2.5rem')};
  padding-bottom: ${(p) => (p.reverseMobile ? '2.5rem' : 0)};

  ${desktop} {
    padding-bottom: 0;
    padding-top: 0;
    min-height: fit-content;
  }
`;

export const TextContainer = styled(GridItem)<CTAProps>`
  grid-row: ${(p) => (p.reverseMobile ? 2 : 1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: 1.25rem;
  gap: 1rem;

  ${desktop} {
    grid-row: 1;
    grid-column-start: ${(p) =>
      p.reverseDesktop ? 'main-right-offset' : 'main'};
    grid-column-end: ${(p) =>
      p.reverseDesktop ? 'main-right-end' : 'main-left-stop'};
    padding-block: 6.25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled(MDSText.HeroSm)``;

export const Description = styled(MDSText.BodyRegDefault)``;

export const Button = styled(MDSAnchorButton)`
  margin-top: 0.5rem;
  width: 100%;

  ${desktop} {
    width: fit-content;
  }
`;

export const ChevronRight = styled(ChevronRightIcon)``;

export const ImageContainer = styled(GridItem)<CTAProps>`
  grid-row: ${(p) => (p.reverseMobile ? 1 : 2)};

  ${desktop} {
    grid-row: 1;
    grid-column-start: ${(p) =>
      p.reverseDesktop ? 'main-left-start' : 'main-left-end'};
    grid-column-end: ${(p) =>
      p.reverseDesktop ? 'main-right-start' : 'main-right-end'};
  }
`;

export const ImageContainerBreakout = styled(ImageContainer)<CTAProps>`
  grid-column-start: breakout-left;
  grid-column-end: breakout-right;

  ${desktop} {
    grid-column-start: ${(p) =>
      p.reverseDesktop ? 'breakout-left' : 'main-right-start'};
    grid-column-end: ${(p) =>
      p.reverseDesktop ? 'main-left-end' : 'breakout-right'};
  }
`;

export const Image = styled(NextImage)`
  width: 100%;
  height: 100%;
  object-fit: ${(p) => (p.objectFit ? p.objectFit : 'cover')};
`;
