import { sectionContainerWithPaddingBreakpoint } from './container';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const breakoutWidthPixels = 2000;
export const breakoutWidthRem = 125;
export const breakoutWidthBreakpoint = `@media screen and (min-width: ${breakoutWidthPixels}px)`;

// --------------------------------------------------------------------------

export const makeGridTemplateColumns = (
  gutter: string | number,
  breakout: string | number,
  main: string | number,
) => {
  return (
    `[gutter-left] ${gutter} ` +
    `[breakout breakout-left] ${breakout} ` +
    `[main main-start main-left main-left-start main-left-begin breakout-left-end] ${main} ` +
    `repeat(3, [main main-left] ${main}) ` +
    `[main main-left main-left-offset] ${main} ` +
    `[main main-left main-left-stop] ${main} ` +
    `[main main-left-end main-right main-right-start main-right-begin] ${main} ` +
    `[main main-right main-right-offset] ${main} ` +
    `repeat(3, [main main-right] ${main}) ` +
    `[main main-right main-right-stop] ${main} ` +
    `[breakout breakout-right main-end main-right-end] ${breakout} ` +
    `[breakout-right-end gutter-right] ${gutter}`
  );
};

// --------------------------------------------------------------------------

export const GridContainer = styled.div<{ forceMobileBreakpoint?: boolean }>`
  ${({ forceMobileBreakpoint }) => `
    padding-inline: 0;
    display: grid;
    grid-template-columns: ${makeGridTemplateColumns(0, '1rem', '1fr')};
    width: 100%;

    ${
      !forceMobileBreakpoint &&
      `
      ${sectionContainerWithPaddingBreakpoint} {
        grid-template-columns: ${makeGridTemplateColumns(0, '1fr', '6.333rem')};
      }

      ${breakoutWidthBreakpoint} {
        grid-template-columns: ${makeGridTemplateColumns(
          '1fr',
          '24.5rem',
          '6.333rem',
        )};
      }
    `
    }
  `}
`;

export const GridItem = styled.div`
  grid-column: main;
`;
