'use client';

import { AnimateIn } from '@exploration/shared-components';
import { desktop } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const SectionIntroContainer = styled.div`
  text-align: center;
  max-width: 32rem;
  margin: 0 auto 2rem;
  ${desktop} {
    max-width: 52rem;
  }
`;

export const SectionIntroTitle = styled(MDSText.HeroLg)`
  font-size: 1.75rem;
  ${desktop} {
    font-size: 2.25rem;
  }
`;

export const SectionIntroTitleH1 = styled(MDSText.HeroXl)`
  ${desktop} {
    font-size: 3.25rem;
  }
`;

export const SectionIntroDescription = styled(MDSText.Subtitle)`
  margin-top: 0.5rem;
  color: ${(p) => p.theme.midwestColors.textTertiary};
  ${desktop} {
    margin-top: 1rem;
  }
`;

// --------------------------------------------------------------------------

export const SectionIntro = ({
  title,
  description,
  className,
  headerType = 'h2',
}: {
  title?: string;
  description?: string;
  className?: string;
  headerType?: 'h1' | 'h2' | 'h3' | 'h4';
}) => (
  <SectionIntroContainer className={className}>
    <AnimateIn animateClass="animate__fadeInUp">
      {!!title && headerType === 'h1' && (
        <SectionIntroTitleH1 as={headerType}>{title}</SectionIntroTitleH1>
      )}
      {!!title && headerType !== 'h1' && (
        <SectionIntroTitle as={headerType}>{title}</SectionIntroTitle>
      )}
      {!!description && (
        <SectionIntroDescription as="p">{description}</SectionIntroDescription>
      )}
    </AnimateIn>
  </SectionIntroContainer>
);
