import {
  Button,
  ChevronRight,
  Container,
  Description,
  Image,
  ImageContainer,
  ImageContainerBreakout,
  Section,
  TextContainer,
  Title,
} from './cta-with-image.style';
import { CTAWithImageSectionProps } from './cta-with-image.types';

// --------------------------------------------------------------------------

export const CTAWithImageStyles = {
  Section,
  Container,
  TextContainer,
  Title,
  Description,
  Button,
  ChevronRight,
  ImageContainer,
  ImageContainerBreakout,
  Image,
};

// --------------------------------------------------------------------------

export const CTAWithImageSection = ({
  backgroundColor,
  callToAction,
  image,
  reverseDesktop,
  reverseMobile,
  textComponent,
  imageComponent,
  imageBreakout,
  className,
  id,
}: CTAWithImageSectionProps) => {
  const ImageContainerType = imageBreakout
    ? ImageContainerBreakout
    : ImageContainer;

  return (
    <Section id={id} backgroundColor={backgroundColor} className={className}>
      <Container reverseDesktop={reverseDesktop} reverseMobile={reverseMobile}>
        <TextContainer
          reverseDesktop={reverseDesktop}
          reverseMobile={reverseMobile}
        >
          {textComponent ? (
            textComponent
          ) : (
            <>
              {!!callToAction?.title && (
                <Title as="h2">{callToAction.title.children}</Title>
              )}
              {!!callToAction?.description && (
                <Description as="p">
                  {callToAction.description.children}
                </Description>
              )}
              {!!callToAction?.link && !!callToAction?.text && (
                <Button
                  id={callToAction?.buttonId}
                  href={callToAction.link}
                  aria-label={callToAction?.ariaId}
                >
                  {callToAction.text}
                  <ChevronRight />
                </Button>
              )}
            </>
          )}
        </TextContainer>
        <ImageContainerType
          reverseDesktop={reverseDesktop}
          reverseMobile={reverseMobile}
        >
          {imageComponent
            ? imageComponent
            : !!image?.src &&
              !!image?.alt && (
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={image.width}
                  height={image.height}
                  objectFit={image.objectFit}
                />
              )}
        </ImageContainerType>
      </Container>
    </Section>
  );
};
